/**
 * @global jQuery
 */

( function( window, $ ) {
	'use strict';

	$( '#whisk-collections-trigger' ).on(
		'click',
		function () {
			const $BUTTON = $( this );

			$BUTTON.addClass( 'whisk-btn-loading' );

			whisk.queue.push( function() {
				whisk.shoppingList.addRecipeToCollection(
					{
						recipeUrl: $BUTTON.data( 'url' )
					}
				);

				setTimeout(
					function() {
						$BUTTON.removeClass( 'whisk-btn-loading' );
					},
					3000
				);
			} );
		}
	);

	/**
	 * View shopping list.
	 */
	whisk.queue.push(
		function() {
			whisk.listeners.addClickListener(
				'whisk-shopping-list-view',
				'shoppingList.viewList',
				{}
			);
		}
	);

	/**
	 * Add recipe to shopping list.
	 */
	whisk.queue.push(
		function() {
			whisk.listeners.addClickListener(
				'whisk-shopping-list-trigger',
				'shoppingList.addRecipeToList',
				{
					recipeUrl: window.whisk_recipes.permalink
				}
			);
		}
	);

	/**
	 * Add recipe to shopping list.
	 */
	whisk.queue.push(
		function () {
			whisk.events.subscribe(
				'whisk-shopping-list-trigger',
				'click',
				function ( e ) { console.log( e );

					const $BUTTON = $( e.data.element );
					const $SCALE  = $( '#whisk-shopping-list-scale' );

					let scale = $SCALE.val() / $SCALE.data( 'original-scale' );

					$BUTTON.addClass( 'whisk-btn-loading' );

					whisk.queue.push(
						function() {
							whisk.shoppingList.addRecipeToList(
								{
									scale: parseFloat( scale )
								}
							);

							setTimeout(
								function() {
									$BUTTON.removeClass( 'whisk-btn-loading' );
								},
								3000
							);
						}
					);
				}
			)
		}
	)
} )( window, jQuery );
