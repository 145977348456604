import { formatQuantity } from '@whisklabs/amounts';
import { isFractionalUnit } from '@whisklabs/amounts';
import { getFractions } from '@whisklabs/amounts';
import { dropZeros } from '@whisklabs/amounts';

jQuery( function ( $ ) {
	var $document = $( document ),
		ajax_url  = whisk_recipes.ajax_url,
		nonce     = whisk_recipes.nonce;

	var replacements = {
		cup: {
			unit: 'liter',
			amount: 0.24
		},
		pint: {
			unit: 'liter',
			amount: 0.47
		},
		teaspoon: {
			unit: 'milliliters',
			amount: 4.93
		},
		gallon: {
			unit: 'liters',
			amount: 3.79
		},
		ounce: {
			unit: 'grams',
			amount: 28.35
		},
		pound: {
			unit: 'kilogram',
			amount: 0.45
		}
	};

	let fraction = {
		'¼': 0.25,
		'½': 0.5,
		'⅕': 0.2,
		'¾': 0.75,
		'⅓': 0.33,
		'⅛': 0.125,
		'⅜': 0.375,
	}

	/**
	 * Tabs.
	 */
	$( 'dl.whisk-tabs dt.whisk-tabs__item' ).click( function() {
		$( this )
			.siblings()
			.removeClass( 'whisk-tabs__item_selected' )
			.end()
			.next( 'dd' )
			.andSelf()
			.addClass( 'whisk-tabs__item_selected' );
	} );

	/**
	 * Toggle video for Instructions section.
	 */
	$( '.whisk-instructions .whisk-tabs__item' ).click(
		function() {
			var $tab    = $( this ),
				$videos = $tab.parents( '.whisk-instructions' ).find( '.whisk-instructions__video' );

			$videos.toggleClass( 'whisk-hidden' );
		}
	);

	$( '.whisk-single a > img' ).parent().on(
		'click',
		function ( e ) {
			var $this = $( this ),
				width = $this.data( 'width' ) || 800;

			e.preventDefault();
			$( '#whisk-modal-photo img' ).attr( 'src',  $this.attr( 'href' ) );
			$( '#whisk-modal-photo' ).modal2(
				{
					width: width
				}
			);
		}
	);

	var $modal_tooglers = $( '[data-toggle="modal"]' );

	$modal_tooglers.on(
		'click',
		function ( e ) {
			e.preventDefault();

			var $this   = $( this ),
				width   = $this.data( 'width' ) || 800,
				target  = $this.attr( 'href' ) || $this.data( 'target' ),
				content = $this.data( 'content' ) || false;

			if ( content ) {
				$( target ).modal2(
					{
						width: width,
						content: content
					}
				);
			} else {
				$( target ).modal2(
					{
						width: width,
					}
				);
			}
		}
	);

	var $magnific_togglers = $( '[data-toggle="magnific__"]' );

	$magnific_togglers.on(
		'click',
		function () {
			let $this = $( this ),
				url   = $this.data( 'mfp-src' ),
				params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,rel=opener`;

				let win = window.open( url, 'zalupa', params );

				win.addEventListener(
					'message',
					function ( event ) {
						console.log( event );
					}
				);

				// setInterval(
				// 	function () {
				// 		console.log( win );
				// 	},
				// 	3000
				// );

		}
	);

	var $magnific_togglers2 = $( '[data-toggle="magnific"]' );

	$magnific_togglers2.on(
		'click',
		function ( e ) {
			e.preventDefault();

			var $this   = $( this ),
				width   = $this.data( 'width' ) || 700,
				height  = $this.data( 'height' ) || 500,
				type    = $this.data( 'type' ) || 'inline',
				target  = $this.attr( 'href' ) || $this.data( 'target' ),
				content = $this.data( 'content' ) || false;

			$this.magnificPopup({
				type: type,
				width: width,
				height: height,
				callbacks: {
					open: function () {
						$( this.container )
							.find( '.mfp-content' )
							.css(
								{
									'width': width,
									'height': height,
								}
							)
							.find( '.mfp-iframe' )
							.css(
								{
									'width': width,
									'height': height,
								}
							);
					}
				}
			}).magnificPopup( 'open' );
		}
	);



	/**
	 * Styling for input:number.
	 */
	$( '.whisk-input_number' ).each(
		function () {
			var $input_number = $( this );

			$input_number.wrap( '<div class="whisk-input-wrapper"></div>' );
			$input_number.before( '<input type="button" class="whisk-input_number__minus" value="-" />' );
			$input_number.after( '<input type="button" class="whisk-input_number__plus" value="+" />' );


		}
	);

	$document.on(
		'input',
		'.whisk-input_number',
		function ( e ) {
			const $field = $( this );
			const min    = $field.attr( 'min' ) || 1;
			const max    = $field.attr( 'max' ) || 999;

			let value = parseInt( $field.val() ) || min;

			if ( value <= min ) {
				value = min;
			}

			if ( value >= max ) {
				value = max;
			}

			$field.val( value );

			update_ingredients_amount( value );
		}
	);

	$document.on(
		'click',
		'.whisk-input_number__minus',
		function () {
			var $input_number = $( this ).next(),
				input_step    = $input_number.attr( 'step' ) || 1,
				input_min     = $input_number.attr( 'min' ) || 1,
				old_value     = parseFloat( $input_number.val() ),
				new_value;

			if ( old_value <= input_min ) {
				new_value = old_value;
			} else {
				new_value = old_value - input_step;
			}

			$input_number.val( new_value ).trigger( 'change' );
			update_ingredients_amount( new_value );
		}
	);

	$document.on(
		'click',
		'.whisk-input_number__plus',
		function () {
			var $input_number = $( this ).prev(),
				input_step    = $input_number.attr( 'step' ) || 1,
				input_max     = $input_number.attr( 'max' ) || 100,
				old_value     = parseFloat( $input_number.val() ),
				new_value;

			if ( old_value >= input_max ) {
				new_value = old_value;
			} else {
				new_value = old_value + input_step;
			}

			$input_number.val( new_value ).trigger( 'change' );
			update_ingredients_amount( new_value );
		}
	);

	$document.on(
		'click',
		'.whisk-section_ingredients dt',
		function() {
			var $this  = $( this ),
				$units = $( '.whisk-ingredients__unit' ),
				type   = $this.data( 'type' );

			$units.each( function () {
				var $unit           = $( this ),
					original_unit   = $unit.data( 'original-unit' ).toLowerCase(),
					$amount         = $unit.parent().find( '.whisk-ingredients__amount' ),
					original_amount = $amount.data( 'original-amount' );

				if ( 'metrics' === type ) {
					$unit.text( original_unit );
					$amount.text( original_amount );
				} else {
					if ( replacements[ original_unit ] ) {
						$unit.text( replacements[ original_unit ]['unit'] );
						$amount.text( replacements[ original_unit ]['amount'] * original_amount );
					}
				}
			} );
		}
	);

	/**
	 * Ratings.
	 */
	$document.on(
		'click',
		'.whisk-rating__input',
		function ( ) {
			var $this   = $( this ),
				$votes  = $this.parents( '.whisk-rating' ).find( '.whisk-rating__votes' ),
				$rating = $this.parents( '.whisk-rating' ).find( '.whisk-rating__value' ),
				$nonce  = $this.parents( '.whisk-rating' ).find( '.whisk-rating__nonce' );

			$.post(
				ajax_url,
				{
					action: 'whisk_recipes_rating',
					recipe_id: $this.data( 'recipe-id' ),
					rating: $this.val(),
					_ajax_nonce: $nonce.val()
				},
				function ( response ) {
					if ( response && response.success ) {
						console.log( response );
						$votes.text( response.data.votes );
						$rating.text( response.data.rating );
					} else {
						console.log( response );
					}
				}
			);
		}
	);

	function in_array( needle, haystack, strict ) {

		var found  = false,
			key,
			strict = !! strict;

		for ( key in haystack ) {
			if ( ( strict && haystack[ key ] === needle ) || ( ! strict && haystack[ key ] == needle ) ) {
				found = true;
				break;
			}
		}

		return found;
	}

	function array_search( obj, needle ) {

		var result = false;

		$.each( fraction, function( key, value ) {

			if ( value === needle ) {
				result = key;

				return false;
			}
		} );

		return result;
	}

	function convert_fraction_to_decimal( str ) {

		if ( fraction[ str ] ) {
			return fraction[ str ];
		}

		return str;
	}

	function convert_decimal_to_fraction( str ) {

		let key = array_search( fraction, str );

		if ( key ) {
			return key;
		}

		return str
	}

	function update_ingredients_amount( value ) {
		var $amounts = $( '.whisk-ingredients .whisk-ingredients__amount' );

		$amounts.each( function () {
			var $amount         = $( this ),
				$unit           = $amount.parent().find( '.whisk-ingredients__unit' ),
				unit            = $unit.data( 'original-unit' ) || 'pcs',
				amount          = $amount.data( 'original-amount' ),
				original_amount = amount;

			if ( amount <= 0 ) {
				//return;
			}

			amount = convert_fraction_to_decimal( amount );
			$amount.html( formatQuantity( amount * value, unit ) );
		} );
	}

	/**
	 *
	 * @param amount
	 * @param unit
	 * @returns string
	 */
	function convert_us_customary_to_metric( amount, unit ) {
		var replacements = [
			{
				cup: {
					unit: 'liter',
					amount: 0.24
				},
				pint: {
					unit: 'liter',
					amount: 0.47
				},
				teaspoon: {
					unit: 'milliliters',
					amount: 4.93
				},
				gallon: {
					unit: 'liters',
					amount: 3.79
				},
				ounce: {
					unit: 'grams',
					amount: 28.35
				},
				pound: {
					unit: 'kilogram',
					amount: 0.45
				}
			}
		];

		return replacements;
	}
});
